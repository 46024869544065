<template>
    <div>
        <div class="row">
            <div class="col-12 text-center">
                <div style="width: 20%; display: inline-block;">
                    <button style="width: 100%;" class="btn teamButton" @click="selectTab('team')" :class="{'active': tabs.team}">Team Stats</button>
                </div>
                <div style="width: 20%; display: inline-block;">
                    <button style="width: 100%;" class="btn claimsButton" @click="selectTab('claims')" :class="{'active': tabs.claims}">Claims</button>
                </div>
                <div style="width: 20%; display: inline-block;">
                    <button style="width: 100%;" class="btn parkingButton" @click="selectTab('parking')" :class="{'active': tabs.parking}">Parking</button>
                </div>
                <div style="width: 20%; display: inline-block;">
                    <button style="width: 100%;" class="btn appealsButton" @click="selectTab('appeals')" :class="{'active': tabs.appeals}">Appeals</button>
                </div>
                <div style="width: 20%; display: inline-block;">
                    <button style="width: 100%;" class="btn trainingButton" @click="selectTab('training')" :class="{'active': tabs.training}">Training</button>
                </div>
            </div>
        </div>

        <div class="row" style="width: 100%;">
            <div class="col-12">
                <Team v-show="tabs.team" @changeUser="handleChangeUser"></Team>
                <Claims :user_id="selectedUser" v-show="tabs.claims"></Claims>
                <Parking :user_id="selectedUser" v-show="tabs.parking"></Parking>
                <Appeals :user_id="selectedUser" v-show="tabs.appeals"></Appeals>
                <Training v-show="tabs.training"></Training>
            </div>
        </div>
    </div>
</template>

<script>
import Team from "./Team.vue";
import Claims from "./Claims.vue";
import Parking from "./Parking.vue";
import Appeals from "./Appeals.vue";
import Training from "./Training.vue";

export default {
    name: 'Dashboard',
    components: {
        Team,
        Claims,
        Parking,
        Appeals,
        Training
    },
    data(){
        return {
            tabs: {
                team: true,
                claims: false,
                parking: false,
                appeals: false,
                training: false
            },
            selectedUser: null,
            loadingStats: true,
            loadingPersonalStats: true,
            stats: {
                total: 9999,
                open: 9999,
                pending: 9999,
                paid: 9999,
                processed: 9999,
                untouched: 9999,
                tampered: 9999,
                locked: 9999,
                dvla: 9999,
                mailed: 9999,
                reminders: 9999,
                post_failed: 9999,
                fuel_debt_collection: 9999,
                parking_debt_collection: 9999,
                notInBatch: 9999,
                keeperAppealed: 9999,
            },
            personalStats: {
                paid: 9999,
                processed: 9999,
                processed_previous: 9999,
                view_duration: {
                    day: "00:00:00",
                    week: "00:00:00",
                    month: "00:00:00"
                }
            },
            processors: [],
            selectedUser: this.$store.state.auth.user.id,
            mistakes: [],
            mistakePagination: {
                currPage: 1,
                totalResults: 0,
                from: 0,
                to: 0,
                perPage: 0,
                lastPage: 1,
            },
            mistake: {
                user_id: null,
                description: "",
                creating: false
            }
        }
    },
    mounted(){
        
    },
    methods: {
        handleChangeUser(user){
            this.selectedUser = user;
        },
        selectTab(tab){
            for(let key in this.tabs){
                this.tabs[key] = false;
            }
            this.tabs[tab] = true;
        }
    }
}
</script>

<style scoped>

.teamButton {
    border-bottom: 4px solid rgba(220, 52, 68, 0.3);
    background-color: #182532;
    color: white;
    font-weight: bold;
}

.claimsButton {
    border-bottom: 4px solid rgba(15, 192, 9, 0.3);
    background-color: #182532;
    color: white;
    font-weight: bold;
}

.parkingButton {
    border-bottom: 4px solid rgba(7, 102, 197, 0.3);
    background-color: #182532;
    color: white;
    font-weight: bold;
}

.appealsButton {
    border-bottom: 4px solid rgba(244, 153, 21, 0.3);
    background-color: #182532;
    color: white;
    font-weight: bold;
}

.trainingButton {
    border-bottom: 4px solid rgba(97, 25, 214, 0.3);
    background-color: #182532;
    color: white;
    font-weight: bold;
}

.teamButton.active {
    border-bottom: 4px solid #dc3444;
}

.claimsButton.active {
    border-bottom: 4px solid #0fc009;
}

.parkingButton.active {
    border-bottom: 4px solid #0766c5;
}

.appealsButton.active {
    border-bottom: 4px solid #f49915;
}

.trainingButton.active {
    border-bottom: 4px solid #6119d6;
}

</style>