<template>

  <div>

    <div style="width: 100%; margin-top: 20px;">
      <div class="row">
        <!-- TRAINING LOG -->
        <div class="col-4">

          <div class="input-group mg-b-20" v-show="$can('claims-manage-team')">
            <button class="btn btn-primary" style="width: 100%;" @click="newTraining.modal.show()">Add Training</button>
          </div>

          <div class="input-group">
            <input type="text" @keydown.enter="getTraining" v-model="search" class="form-control" placeholder="Search for training...">
            <div class="input-group-append">
              <button class="btn btn-primary" type="button" @click="getTraining"><i class="fa fa-search"></i></button>
            </div>
          </div>

          <table class="table table-striped table-hover tx-white" style="margin-top: 20px;">
            <thead class="thead-colored thead-primary">
              <th>Date</th>
              <th>Topic(s)</th>
              <th></th>
            </thead>
            <tbody>
              <tr v-for="t in training" @click="selectedTraining = t" :class="{'selected': selectedTraining === t}">
                <td>{{ t.date }}</td>
                <td>{{ t.topics }}</td>
                <td>
                  <button class="btn btn-sm btn-primary">View Notes</button>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <!-- TRAINING NOTES -->
        <div class="col-8 tx-white">

          <div class="row">
            <div class="col">
              <h2 style="color: white; text-decoration: underline;">TRAINING NOTES <span v-show="selectedTraining">{{ selectedTraining?.date }}</span> <span v-show="!selectedTraining">(PLEASE SELECT ONE)</span></h2>
            </div>
          </div>

          <pre style="color: white; max-width: 100%; overflow-x: auto; white-space: pre-wrap;">
  {{ selectedTraining?.notes }}
          </pre>

        </div>
      </div>
    </div>

    <div id="addTrainingModal" class="modal fade" style="display: none; padding-right: 17px;">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content bg-dark tx-white tx-size-sm">
              <div class="modal-header bg-dark pd-x-20">
                  <h6 class="tx-14 mg-b-0 tx-uppercase tx-white tx-bold">Add Training Log</h6>
                  <button type="button" class="close tx-white" data-bs-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                  </button>
              </div>
              <div class="modal-body bg-dark tx-white pd-20">

                  <div class="form-group">
                      <label for="date">Date of Training <span class="text-danger">*</span></label>
                      <input type="date" v-model="newTraining.date" class="form-control form-control-dark" />
                  </div>

                  <div class="form-group">
                      <label for="topics">Topics <span class="text-danger">*</span></label>
                      <input type="text" v-model="newTraining.topics" class="form-control form-control-dark" />
                  </div>

                  <div class="form-group">
                      <label for="notes">Training Notes <span class="text-danger">*</span></label>
                      <textarea rows="5" v-model="newTraining.notes" class="form-control form-control-dark" />
                  </div>

                  <button :disabled="newTraining.creating" class="btn btn-primary float-right" @click="postTrainingLog">Record Notes</button>
                  
              </div><!-- modal-body -->
              <div class="modal-footer bg-dark tx-white">
                  <button type="button" class="btn btn-secondary tx-size-xs" data-bs-dismiss="modal">Close</button>
              </div>
          </div>
      </div><!-- modal-dialog -->
    </div>

  </div>

</template>

<script>
import axios from 'axios';
import authHeader from '../../services/auth-header';

export default {
  name: 'Training',
  data(){
    return {
      training: [],
      loading: false,
      search: '',
      selectedTraining: null,
      newTraining: {
        date: new Date().toISOString().split('T')[0],
        topics: null,
        notes: null,
        modal: null,
        creating: false
      }
    }
  },
  mounted(){
    this.newTraining.modal = new bootstrap.Modal(document.getElementById('addTrainingModal'));
    this.getTraining();
  },
  methods: {
    getTraining(){
      this.loading = true;
      axios.get(`https://api.varsanpr.com/api/dashboard/claims/training`, {
        headers: authHeader(),
        params: {
          search: this.search
        }
      })
      .then(response => {
        this.training = response.data.trainingLogs;
        this.training.forEach((t) => {
          t.date = new Date(t.date).toLocaleDateString();
        })
        this.loading = false;
      })
      .catch(e => {
        console.log(e);
        this.$error('An error occurred while fetching training data.', e);
      });
    },
    postTrainingLog(){
      this.newTraining.creating = true;
      axios.post(`https://api.varsanpr.com/api/dashboard/claims/training`, {
        topics: this.newTraining.topics,
        notes: this.newTraining.notes,
        date: this.newTraining.date
      }, {
        headers: authHeader()
      })
      .then(response => {
        this.newTraining.creating = false;
        this.newTraining.modal.hide();
        this.getTraining();
      })
      .catch(e => {
        console.log(e);
        this.$error('An error occurred while posting training data.', e);
      })
      .finally(() => {
        this.newTraining.creating = false;
      });
    }
  }
}

</script>